import React from "react";
import "./About.scss";
import ProfilePic from "../images/profile_pic.jpg";
import LinkedInPhoto from "../images/linkedin.png";

const About: React.FC = () => {
  return (
    <div className='about'>
      <div className='about__title'>
        <div className='about__title-header'>About me</div>
        <div className='about__title-divider'></div>
      </div>

      <div className='about__picture'>
        <img src={ProfilePic} alt='profile pic' className='profile_pic' />
      </div>
      <div className='about__bio'>
        <h1 className='hello'>Hello!</h1>

        <p className='about__bio-sentence'>
          I like building aesthetically designed websites! I am a rising junior
          developer continuously learning new techniques and technology.
        </p>
        <p className='about__bio-sentence'>
          If you have an inquiry or want to chat, feel free to send me an email!
        </p>

        <div className='about__bio-link'>
          <a href='https://www.linkedin.com/in/gideonubaldo/'>
            <img src={LinkedInPhoto} alt='LinkedIn' className='linkedin' />
          </a>
        </div>
      </div>

      <div className='about__interest'>
        <h1 className='personal_interest'>Personal Interest</h1>

        <p className='about__interest-sentence'>
          When I'm not coding, I enjoy spending my time doing any of the
          following:
        </p>

        <ul className='interest__list'>
          <li>Playing guitar</li>
          <li>Singing</li>
          <li>Pingpong</li>
          <li>Watching eSports</li>
          <li>Chess</li>
          <li>Exploring new food spots</li>
        </ul>
      </div>
    </div>
  );
};

export default About;
