import React from "react";
import "./Skills.scss";

const Skills: React.FC = () => {
  return (
    <div className='skills'>
      <div className='skills__title'>
        <div className='skills__title-header'>Technical Skills</div>
        <div className='skills__title-divider'></div>
      </div>

      <div className='skills__paragraph'>
        I've worked with a wide variety of programming languages. I mainly use
        Javascript for web applications. I tend to use python for anything that
        is machine-learning related. Overall, I am continually learning and
        deep-diving into those two aforementioned languages.
      </div>

      <h1 className='top-used'>Top Used Tools</h1>

      <div className='top-used__grid'>
        <div className='top-used__list'>
          <div className='top-used__list-frameworks'>
            <h1 className='frameworks-title'>Frameworks / Languages</h1>
            <ul className='frameworks'>
              <li>React.js</li>
              <li>Javascript</li>
              <li>Python</li>
            </ul>
          </div>
          <div className='top-used__list-software'>
            <h1 className='software-title'>Software</h1>
            <ul className='software'>
              <li>Git</li>
              <li>Linux</li>
              <li>Bash</li>
              <li>Selenium</li>
              <li>Postman</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
