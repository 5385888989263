import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/home/Home";
// import App from "./App";
// import Contact from "./pages/contact/Contact";

ReactDOM.render(
  <Router>
    {/* <App /> */}
    <Switch>
      <Route path='/' exact component={Home} />
      {/* <Route path='/contact' exact component={Contact} /> */}
    </Switch>
  </Router>,
  document.getElementById("root")
);
