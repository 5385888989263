import React from "react";
import "./Home.scss";
import Header from "../../components/header/Header";
import About from "../../components/about/About";
import Skills from "../../components/skills/Skills";
import WorkExperience from "../../components/workexperience/WorkExperience";
import Education from "../../components/education/Education";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import ContactForm from "../../components/contactform/ContactForm";

const Home: React.FC = () => {
  return (
    <div className='home'>
      <Header />
      <Navbar />
      <About />
      <Skills />
      <WorkExperience />
      <Education />

      <ContactForm />
      <Footer />
    </div>
  );
};

export default Home;
