import React from "react";
import "./Education.scss";
import School from "../school/School";

const Education: React.FC = () => {
  return (
    <div className='education'>
      <div className='education__title'>
        <div className='education__title-header'>Education</div>
        <div className='education__title-divider'></div>
      </div>

      <School />
    </div>
  );
};

export default Education;
