import React, { useEffect, useState } from "react";
import "./Navbar.scss";
import $ from "jquery";

const Navbar: React.FC = () => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    // for sticky navbar
    let height = $(".header").height();
    let navheight = $(".navbar").height();

    height = height! - navheight!;
    $(window).scroll(function () {
      if ($(this).scrollTop()! > height!) {
        $(".navbar").addClass("fixed");
      } else {
        $(".navbar").removeClass("fixed");
      }
    });

    function handleResize() {
      setWindowHeight(window.innerHeight);
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const navigateTo = (e) => {
    let target = e.currentTarget.getAttribute("id");
    const anchor = document.querySelector(`.${target}`)!;
    anchor.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className='navbar'>
      <ul className='navbar__list'>
        <li className='list-item'>
          <div id='about' onClick={navigateTo} className='list-item__text'>
            About
          </div>
        </li>
        <li className='list-item'>
          <div id='skills' onClick={navigateTo} className='list-item__text'>
            Skills
          </div>
        </li>
        <li className='list-item'>
          <div
            id='work-experience'
            onClick={navigateTo}
            className='list-item__text'
          >
            Experience
          </div>
        </li>
        <li className='list-item'>
          <div id='education' onClick={navigateTo} className='list-item__text'>
            Education
          </div>
        </li>
        <li className='list-item'>
          <div
            id='contact-form'
            onClick={navigateTo}
            className='list-item__text'
          >
            Contact
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
