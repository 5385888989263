import React from "react";
import "./Footer.scss";
import Logo from "../images/white-logo.png";

const Footer: React.FC = () => {
  return (
    <div className='footer'>
      <img src={Logo} alt='main-logo' className='main-logo' />
    </div>
  );
};
export default Footer;
