import React from "react";
import "./Header.scss";

const Header: React.FC = () => (
  <header className='header'>
    <div className='header__content'>
      <div className='header__content-name'>Gideon Ubaldo</div>
      <div className='header__content-devider'></div>
      <div className='header__content-title'>Software Engineer</div>
    </div>
  </header>
);
export default Header;
