import React from "react";
import "./School.scss";

const School: React.FC = () => {
  return (
    <div className='school'>
      <h1 className='school__title'>San Jose State University</h1>
      <ul className='school__keywords'>
        <li>Bachelor of Software Engineer</li>
        <li>Front-End</li>
      </ul>

      <p className='school__description'>
        I studied my upper-division classes at SJSU, exploring profound studies
        mainly in databases, data structures and algorithms, web development,
        iOS development, and machine learning.
      </p>

      <div className='school__graduate'>
        <span className='school__graduate-prompt'>Graduated in: </span>

        <span className='school__graduate-description'>Early 2019</span>
      </div>
    </div>
  );
};

export default School;
