import React from "react";
import "./WorkExperience.scss";
import Job from "../job/Job";
import JobData from "../../data/job.json";

const WorkExperience: React.FC = () => {
  console.log(`JobData`, JobData);
  return (
    <div className='work-experience'>
      <div className='work-experience__title'>
        <div className='work-experience__title-header'>Work Experience</div>
        <div className='work-experience__title-divider'></div>
      </div>

      {JobData.jobs.map((job) => (
        <Job key={job.title} {...job} />
      ))}
    </div>
  );
};

export default WorkExperience;
