import { useForm } from "react-hook-form";
import React from "react";
import "./ContactForm.scss";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const ContactForm: React.FC = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const toastifySuccess = () => {
    toast.success("Awesome, connect to you soon!!", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const onSubmit = async (data) => {
    try {
      const templateParams = {
        name: data.name,
        email: data.email,
        subject: data.subject,
        message: data.message,
      };

      await emailjs.send(
        process.env.REACT_APP_SERVICE_ID!,
        process.env.REACT_APP_TEMPLATE_ID!,
        templateParams,
        process.env.REACT_APP_USER_ID
      );
      reset();
      toastifySuccess();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <form className='contact-form' onSubmit={handleSubmit(onSubmit)} noValidate>
      <div className='contact-form__heading'>
        <div className='contact-form__heading-title'>Let's Connect!</div>
        <div className='contact-form__heading-divider'></div>
      </div>

      <div className='contact-form__sub_heading'>
        If you would like to build something, want to chat, or say hi, please
        shoot me an email!
      </div>

      {/* Row 1 of form */}
      <div className='contact-form__top'>
        <input
          type='text'
          {...register("name", {
            required: {
              value: true,
              message: "Please enter your name",
            },
            maxLength: {
              value: 30,
              message: "Please use 30 characters or less",
            },
          })}
          name='name'
          placeholder='Name'
        ></input>
        {errors.name && (
          <div className='errorMessage'>{errors.name.message}</div>
        )}

        <input
          type='text'
          {...register("email", {
            required: true,
            pattern:
              /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
          })}
          placeholder='Email address'
          name='email'
        ></input>
        {errors.email && (
          <div className='errorMessage'>Please enter a valid email address</div>
        )}

        <input
          type='text'
          {...register("subject", {
            required: {
              value: true,
              message: "Please enter a subject",
            },
            maxLength: {
              value: 75,
              message: "Subject cannot exceed 75 characters",
            },
          })}
          name='subject'
          className='form-control formInput'
          placeholder='Subject'
        ></input>
        {errors.subject && (
          <div className='errorMessage'>{errors.subject.message}</div>
        )}

        <textarea
          className='contact-form__bottom-message'
          rows={3}
          {...register("message", {
            required: true,
          })}
          name='message'
          placeholder='Message'
        ></textarea>
        {errors.message && (
          <div className='errorMessage'>Please enter a message</div>
        )}
        <button className='btn__item' type='submit'>
          Submit
        </button>
      </div>

      <ToastContainer />
    </form>
  );
};

export default ContactForm;
