import React from "react";
import "./Job.scss";
import { Jobs } from "../../hooks/useJobs";

const Job: React.FC<Jobs> = ({
  title,
  keywords,
  description,
  company,
  period,
  link,
}) => {
  return (
    <div className='job'>
      <h1 className='job__title'>{title}</h1>
      <ul className='job__keywords'>
        {keywords.map((keyword) => (
          <li key={keyword}>{keyword}</li>
        ))}
      </ul>

      <p className='job__description'>{description}</p>

      <div className='job-link'>
        <div className='job-link__company'>
          <span className='job-link__company-prompt'>Company : </span>
          <a href={link} className='job-link__company-description'>
            {company}
          </a>
        </div>
        <div className='job-link__period'>
          <span className='job-link__period-prompt'>Period : </span>
          <span className='job-link__period-description'>{period}</span>
        </div>
      </div>
    </div>
  );
};

export default Job;
